import React, { FC } from 'react';
import { useBi, useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';

import { groupListLoaded } from '@wix/bi-logger-groups/v2';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { Fade } from 'wui/Fade';
import { Container } from 'wui/Container';

import { COMPONENT } from 'settings/consts';

import type { IVMProps } from '../../../vm/types';

import { LayoutRoot } from './Layout';

import { classes } from 'Groups/styles.st.css';

COMPONENT.name = 'GroupListWidget';

const ListWidget: FC<WidgetProps<IVMProps>> = (props) => {
  const { isMobile } = useEnvironment();
  const bi = useBi();

  React.useEffect(() => {
    bi.report(
      groupListLoaded({
        layout_type: 'group_list_widget',
      }),
    );
  }, []);

  return (
    <SocialGroupsApp {...props}>
      <Container
        fluid={isMobile}
        padding={isMobile ? 'SP0' : 'SP5'}
        data-hook="GroupsListWidget-wrapper"
        className={!isMobile ? classes.appBackground : undefined}
      >
        <Fade key="list-widget" timeout={500}>
          <LayoutRoot />
        </Fade>
      </Container>
    </SocialGroupsApp>
  );
};

export default ListWidget;
